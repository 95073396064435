import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import Header from "../layout-elements/header";
import Footer from "../layout-elements/footer";
import "../styles/layout.css";
import "../styles/update.css";

const Layout = ({ children, siteTitle }) => {
  const [scrolledUp, setScrolledUp] = useState(false);
  const [scroll, setScroll] = useState(0);

  const scrollCheckFn = useCallback((e) => {
    const scrollCheck = e.target.scrollingElement.scrollTop;

    setScroll((prevState) => {
      setScrolledUp(prevState.scrollCheck - scrollCheck > 0 ? true : false);
      return { ...prevState, scrollCheck };
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", scrollCheckFn);
    return () => window.removeEventListener("scroll", scrollCheckFn);
  }, []);

  return (
    <div className="">
      <Header scroll={scroll} scrolledUp={scrolledUp} siteTitle={siteTitle} />
      <main className="mt-16 md:mt-20">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
