import React, { useEffect } from "react";
import { Link } from "gatsby";

const CustomLink = ({ children, to, disabled, className, rest }) => {
  const isInternal = () => {
    // console.log(window);
    // if(typeof window === "undefined") return false
    if(typeof window === "undefined"){
      return (to.startsWith("/") && !to.includes("//"));
    }else{
      return to.host === window.location.host || (to.startsWith("/") && !to.includes("//"));
    }
  }

  useEffect(() => {
    isInternal()
  }, [])

  if(isInternal()) return (
    <Link
      to={to}
      disabled={disabled}
      data-info={"internal"}
      className={`${className}`}
      {...rest}
    >
      {children}
    </Link>
  )

  return (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      disabled={disabled}
      data-info={"external"}
      className={`${className}`}
      {...rest}
    >
      {children}
    </a>
  )
};

export default CustomLink;
