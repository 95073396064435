import React from "react";
import CustomLink from "./CustomLink";

const CustomButton = ({ level, children, disabled = false, cb, link, className = "" }) => {
  const buttonStyles = `${className} ${
    level === "primary"
      ? "block px-8 py-3 rounded-3xl  md:px-18 md:py-4 text-xxs md:text-base bg-blue min-w-xsm md:min-w-0 md:rounded-4xl text-white hover:bg-dark_blue text-center disabled:bg-disabled_grey transition-colors duration-200"
      : ""
  }
  ${
    level === "secondary"
      ? "block px-8 py-3 rounded-3xl md:px-18 min-w-xsm md:min-w-0 md:py-4 text-xxs md:text-base bg-white hover:bg-gray-300 text-navy hover:text-blue  md:rounded-4xl text-center disabled:text-disabled_grey transition-colors duration-200"
      : ""
  }
  ${
    level === "tertiary"
      ? "block px-8 py-3 rounded-3xl  md:px-8  md:py-2 text-xxs md:text-base bg-navy hover:bg-gray-500 min-w-xsm md:min-w-0 md:rounded-4xl text-white hover:bg-dark_navy text-center disabled:bg-disabled_grey transition-colors duration-200"
      : ""
  }`;
  if (!link) {
    return (
      <button disabled={disabled} className={buttonStyles} onClick={() => cb()}>
        {children}
      </button>
    );
  } else {
    return (
      <CustomLink disabled={disabled} className={`${buttonStyles} no-underline`} to={link}>
        {children}
      </CustomLink>
    );
  }
};
export default CustomButton;
