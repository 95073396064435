import React from "react";
import { graphql, Link, StaticQuery } from "gatsby";

const Logo = ({ type, className }) => {
  return (
    <StaticQuery
      query={graphql`
        query LogoQuery {
          sanitySiteSettings {
            logo {
              asset {
                url
                metadata {
                  dimensions {
                    height
                    width
                  }
                }
              }
            }
            logoWhite {
              asset {
                url
                metadata {
                  dimensions {
                    height
                    width
                  }
                }
              }
            }
            icon {
              asset {
                url
                metadata {
                  dimensions {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const { logo, logoWhite, icon } = data.sanitySiteSettings;
        switch (type) {
          case "white":
            return (
              <img
                className={`transition-all ${className}`}
                src={logoWhite.asset.url}
                alt="SIGUNA - OEM oil filters for the marine & industry"
                width={Math.floor(logoWhite.asset.metadata.dimensions.width / 10)}
                height={Math.floor(logoWhite.asset.metadata.dimensions.height / 10)}
              />
            );
          case "icon":
            return (
              <img
                className={`transition-all ${className}`}
                src={icon.asset.url}
                alt="SIGUNA - OEM oil filters for the marine & industry"
                width={Math.floor(icon.asset.metadata.dimensions.width / 10)}
                height={Math.floor(icon.asset.metadata.dimensions.height / 10)}
              />
            );
          default:
            return (
              <img
                className={`transition-all ${className}`}
                src={logo.asset.url}
                alt="SIGUNA - OEM oil filters for the marine & industry"
                width={Math.floor(logo.asset.metadata.dimensions.width / 10)}
                height={Math.floor(logo.asset.metadata.dimensions.height / 10)}
              />
            );
        }
      }}
    />
  );
};
export default Logo;
