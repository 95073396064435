import React from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import Logo from "../components/logo";
import CustomLink from "../components/CustomLink";

const FooterBoldText = ({ children, className = "" }) => (
  <strong className={`font-bold uppercase ${className} text-xs`}>{children}</strong>
);

const FooterExtraLinks = ({ title, items = [], bold = false }) => {
  if (bold)
    return items.map((item, key) => {
      if (item._type === "menuItem")
        return (
          <FooterBoldText
            className="w-3/6 py-2 my-0 text-center md:text-left md:w-1/2 lg:w-6/12 xl:w-4/12"
            key={key}
          >
            <CustomLink to={`/${item?.page?.slug?.current}`}>{item.text}</CustomLink>
          </FooterBoldText>
        );

      return (
        <FooterBoldText
          className="w-3/6 py-2 my-0 text-center md:text-left md:w-1/2 lg:w-6/12 xl:w-4/12"
          key={key}
        >
          <CustomLink to={item.link} key={key}>
            {item.text}
          </CustomLink>
        </FooterBoldText>
      );
    });

  return (
    <nav className="w-auto md:w-6/12 lg:w-6/12 lg:pr-16 mt-12 lg:mt-0 xl:w-4/12 ">
      <ul>
        <li className="pb-3">
          <FooterBoldText>{title}</FooterBoldText>
        </li>
        {items.map((item, key) => {
          if (item._type === "menuItem")
            return (
              <li key={key} className="py-1">
                <CustomLink to={`/${item?.page?.slug?.current}`}>{item.text}</CustomLink>
              </li>
            );

          return (
            <li className="py-1" key={key}>
              <CustomLink to={item.link} key={key}>
                {item.text}
              </CustomLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query FooterNavQuery {
          left: sanityMenuOverview(menuPosition: { eq: "footer_left" }) {
            title
            menuItems {
              ... on SanityExternalMenuItem {
                _type
                text
                link
              }
              ... on SanityMenuItem {
                _type
                button
                page {
                  slug {
                    current
                  }
                }
                text
              }
            }
          }
          right: sanityMenuOverview(menuPosition: { eq: "footer_right" }) {
            title
            menuItems {
              ... on SanityExternalMenuItem {
                _type
                text
                link
              }
              ... on SanityMenuItem {
                _type
                button
                page {
                  slug {
                    current
                  }
                }
                text
              }
            }
          }
          bottom: sanityMenuOverview(menuPosition: { eq: "footer_bottom" }) {
            title
            menuItems {
              ... on SanityExternalMenuItem {
                _type
                text
                link
              }
              ... on SanityMenuItem {
                _type
                button
                page {
                  slug {
                    current
                  }
                }
                text
              }
            }
          }
        }
      `}
      render={(data) => {
        const { left, right, bottom } = data;
        return (
          <footer className="text-white p-4 md:py-16 md:px-0 bg-blue rounded-t-3xl md:rounded-t-container flex-shrink-0">
            <div className="container">
              <div className="overflow-hidden my-16 flex flex-col gap-4 items-start w-full md:flex-row md:justify-between md:w-full">
                <Link to="/" className=" md:w-5/12 lg:w-4/12 xl:w-1/4 no-underline">
                  <Logo type="white" className="w-full" />
                </Link>
                <div className="flex justify-between lg:justify-start w-full md:w-6/12 xl:justify-end 2xl:pr-16">
                  <FooterExtraLinks title={left?.title} items={left?.menuItems} />
                  <FooterExtraLinks title={right?.title} items={right?.menuItems} />
                </div>
              </div>
              <div className="border-t border-white border-opacity-30 pt-6 text-xs w-full flex gap-4 flex-col-reverse md:flex-row  items-center md:justify-between">
                <p className="w-full md:text-left text-sm md:w-5/12 lg:w-4/12 text-center 2xl:w-auto">
                  &copy; Copyright {new Date().getFullYear()} - Siguna Industri &amp; Marine ApS |
                  Designed and developed by{" "}
                  <a href="https://www.duckwise.dk" target="_blank" rel="noopener">
                    Duckwise
                  </a>
                </p>
                <div className="flex flex-col items-center w-full md:flex-row md:justify-between  md:w-1/2 xl:justify-end 2xl:pr-16">
                  <FooterExtraLinks items={bottom?.menuItems} bold />
                </div>
              </div>
            </div>
          </footer>
        );
      }}
    />
  );
};

export default Footer;
