import { graphql, Link, StaticQuery } from "gatsby";
import React, { useState, useEffect, useRef } from "react";
import Logo from "../components/logo";
import MenuIcon from "../components/icon/menuIcon";
import "./../styles/menu.css";
import CustomButton from "../components/button";
import CustomLink from "../components/CustomLink";

const Header = ({ siteTitle, scroll, scrolledUp }) => {
  const [showNav, setShowNav] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        query HeaderNavQuery {
          footerMenu: sanityMenuOverview(menuPosition: { eq: "footer_right" }) {
            menuItems {
              ... on SanityExternalMenuItem {
                _type
                text
                link
              }
              ... on SanityMenuItem {
                _type
                button
                page {
                  slug {
                    current
                  }
                }
                text
              }
            }
          }
          sanityMenuOverview(menuPosition: { eq: "header_menu" }) {
            title
            menuItems {
              ... on SanityExternalMenuItem {
                _type
                text
                link
              }
              ... on SanityMenuItem {
                _type
                button
                page {
                  slug {
                    current
                  }
                }
                text
              }
            }
          }
        }
      `}
      render={(data) => {
        const navFirstLevel = data.sanityMenuOverview.menuItems;
        const navFooter = data.footerMenu?.menuItems;

        return (
          <header
            className={`
                flex flex-col lg:flex-row my-4 lg:my-2 inset-x-0 rounded-container transition-all z-50 duration-500 lg:container main-header lg:justify-between lg:items-center mx-auto w-11/12 
                ${
                  showNav
                    ? `forward  h-screen  text-white lg:text-dark_navy `
                    : `return  ${
                        scroll.scrollCheck > 50
                          ? ` lg:p-0  fixed  ${
                              scrolledUp
                                ? " bg-white shadow top-0 p-3 lg:w-10/12 xl:w-3/4"
                                : " left-0 bg-white -top-full"
                            }`
                          : "fixed top-1 md:top-0 lg:px-3 md:w-10/12"
                      }`
                }
                `}
          >
            <div
              className={`justify-between flex w-full lg:w-auto z-40 h-8 ${
                showNav ? "" : "items-center "
              }`}
            >
              <CustomLink className="no-underline" to={"/"} aria-label="Go to homepage">
                <Logo
                  type="icon"
                  className={`md:hidden ${scroll.scrollCheck > 50 ? "h-8" : "h-10"}`}
                />
                <Logo className="hidden md:block h-10 lg:ml-4" />
              </CustomLink>
              <button
                onClick={() => setShowNav(!showNav)}
                className="lg:hidden"
                aria-label="Toggle menu"
              >
                <MenuIcon
                  open={showNav}
                  className={`transition-transform transform ${
                    scroll.scrollCheck > 50 ? "scale-90" : ""
                  }`}
                />
              </button>
            </div>
            <div
              className={`inner z-50 transform md:transform-none transition-transform ${
                showNav
                  ? "text-white"
                  : "-translate-y-full delay-1000 pointer-events-none lg:pointer-events-auto"
              } w-screen lg:w-auto h-screen lg:h-auto fixed lg:relative inset-0 p-7 lg:p-0 bg-dark_navy lg:bg-transparent flex flex-col lg:flex-row  lg:text-dark_navy overflow-scroll lg:overflow-auto`}
            >
              <div className={`justify-between flex w-full z-50 h-8 items-center lg:hidden`}>
                Menu
                <button onClick={() => setShowNav(!showNav)} aria-label="Toggle menu">
                  <MenuIcon
                    open={showNav}
                    className={`transition-transform transform ${
                      scroll.scrollCheck > 50 ? "scale-90" : ""
                    }`}
                  />
                </button>
              </div>
              <div
                className={`z-50 flex-grow items-center justify-center delay-700 transition flex`}
              >
                <ul className={`flex flex-col lg:flex-row items-center text-2xl lg:text-base`}>
                  {navFirstLevel
                    .filter((nfl) => nfl._type === "menuItem")
                    .map((menuItem, i) => (
                      <li className={`py-4 mx-6`} key={i}>
                        {!menuItem.button ? (
                          <CustomLink
                            activeClassName="underline-active"
                            to={`/${menuItem?.page?.slug?.current}`}
                          >
                            {menuItem.text}
                          </CustomLink>
                        ) : (
                          <CustomButton
                            level="tertiary"
                            link={`/${menuItem?.page?.slug?.current}`}
                            className="text-xl md:text-2xl lg:text-base"
                          >
                            {menuItem.text}
                          </CustomButton>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
              <div
                className={
                  "flex lg:hidden flex-col items-center mx-auto sm:flex-row sm:justify-end z-50 border-navy border-solid border-t-2 w-11/12 py-3 pb-6 text-sm delay-700 transition"
                }
              >
                {navFooter.map((menuItem, i) => (
                  <Link
                    activeClassName="underline-active"
                    to={
                      menuItem._type === "menuItem"
                        ? `/${menuItem?.page?.slug?.current}`
                        : menuItem.link
                    }
                    target={menuItem._type !== "menuItem" ? "_blank" : undefined}
                    key={i}
                    rel={menuItem._type !== "menuItem" ? "noopener" : undefined}
                  >
                    {menuItem.text}
                  </Link>
                ))}
              </div>
            </div>
          </header>
        );
      }}
    />
  );
};

export default Header;
