import React from 'react'

const MenuIcon = ({open = false, className}) => {

    return (
        <svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M1 2H17.3333C18.4152 2 19.4679 2.35089 20.3333 3L29 9.5C30.259 10.4443 31 11.9262 31 13.5V20.4539C31 22.0536 30.2346 23.5567 28.9409 24.4976L28.5574 24.7765C26.7485 26.092 24.2861 26.0462 22.5274 24.6644L18.5 21.5L1.5 4.5" strokeLinecap="round" strokeLinejoin="round" style={{fill: "none", strokeWidth: 3}} className={`menu-icon line-1 ${open ? "open" : ""}`}/>
            <path d="M1 24H17.4424C18.4568 24 19.4471 23.6915 20.282 23.1154L28.8396 17.2107C30.1923 16.2773 31 14.7387 31 13.0953V7.35053C31 5.68173 30.1674 4.12294 28.7805 3.19493L27.8955 2.60278C26.4128 1.61077 24.514 1.4838 22.9125 2.2696L18.7815 4.29653C18.2644 4.55027 17.795 4.89146 17.3941 5.30503L1.5 21.7"  strokeLinecap="round" strokeLinejoin="round"  style={{fill: "none", strokeWidth: 3}} className={`menu-icon line-3 ${open ? "open" : ""}`}/>
            <path d="M1 13H26" strokeLinecap="round" style={{fill: "none", strokeWidth: 3}} className={`menu-icon line-2 ${open ? "open" : ""}`}/>
        </svg>
    )
}

export default MenuIcon